import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import DropDownIcon from '@material-ui/icons/KeyboardArrowDownRounded'

const useStyles = makeStyles((theme) => ({
    button: {
        color: ({ buttonColor }) => buttonColor || "inherit", // default to "inherit" if buttonColor is not provided
        fontSize: ({ small }) => (small ? undefined : 18),
        '&:hover': {
            color: 'white',
        },
    },
}))

const DropDownButton = ({ title, small, buttonColor, ...props }) => {
    const classes = useStyles({ small, buttonColor }) // pass buttonColor here

    return (
        <Button
            size="large"
            endIcon={<DropDownIcon />}
            className={classes.button}
            {...props}
        >
            {title}
        </Button>
    )
}

export default DropDownButton
