import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'

import Link from '../../General/Link'

const useStyles = makeStyles((theme) => ({
    item: {
        '&:hover': {
            color:
                theme.palette.type === 'light'
                    ? theme.palette.primary.main
                    : 'inherit',
        },
    },
    icon: {
        marginRight: theme.spacing(1),
    },
}))

const LinkedMenuItem = ({ title, href, click, icon, from, ...props }) => {
    const classes = useStyles()

    let finalHref = href
    if (from) {
        finalHref = `${href}?from_name=${from.name}&from_url=${from.url}`
    }

    return (
        <Link href={finalHref}>
            <MenuItem className={classes.item} onClick={click} {...props}>
                {icon && React.cloneElement(icon, { className: classes.icon })}
                {title}
            </MenuItem>
        </Link>
    )
}

export default LinkedMenuItem
