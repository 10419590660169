import React from 'react';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

import { usePopupState, bindHover, bindMenu } from 'material-ui-popup-state/hooks';

import DropDownButton from '../Items/DropDownButton';
import NavigationMenuItem from '../Items/MenuItem';
import DropDownMenu from './DropDownMenu';

const subRoutes = [
  {
    title: 'Latest Insights',
    slug: '',
    icon: <LibraryBooksIcon />,
  },
];

const BlogsDropdown = ({ small, from }) => {
  const state = usePopupState({
    variant: 'popover',
    popupId: 'blogs-dropdown-menu',
  });

  return (
    <>
      <DropDownButton small={small} title="Insights & Research" buttonColor="#ECC490" {...bindHover(state)} />
      <DropDownMenu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        {...bindMenu(state)}
      >
        {subRoutes.map((subRoutes, i) => (
          <NavigationMenuItem
            key={i}
            title={subRoutes.title}
            href={`/insights/${subRoutes.slug}`}

            icon={subRoutes.icon}
            click={state.close}
            from={from}
          />
        ))}
      </DropDownMenu>
    </>
  );
};

export default BlogsDropdown;