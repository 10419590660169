import React from 'react'
import dynamic from 'next/dynamic'

import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'

import DropDownList from './DropDownList'
import SimpleLoading from './SimpleLoading'

// noinspection JSCheckFunctionSignatures
const AuthBox = dynamic(() => import('../Auth/AuthBoxDrawer'), {
    loading: SimpleLoading,
    ssr: false,
})

const useStyles = makeStyles({
    drawer: {
        width: 250,
    },
})

const DrawerMenu = ({ open, onClose }) => {
    const classes = useStyles()

    return (
        <Drawer anchor="right" open={open} onClose={onClose}>
            <div className={classes.drawer}>
                <List>
                <DropDownList
                        title="Real Estate"
                        items={[
                            {
                                title: 'MLS Search',
                                href: 'real-estate/buy/mls-search',
                            },
                            {
                                title: 'Pre Construction',
                                href: '/project/category/pre-construction',
                            },
                            {
                                title: 'Power of Sale',
                                href: '/project/category/power-of-sale',
                            },
                            {
                                title: 'Assignments',
                                href: '/project/category/assignments',
                            },
                            {
                                title: 'Land',
                                href: '/project/category/land',
                            },
                            {
                                title: 'All Listings',
                                href: '/project/category',
                            },
                        ]}
                    />
                {/* <DropDownList
                        title="Projects"
                        items={[

                            // {
                            //     title: 'Multifamily Residential',
                            //     href: '/project/category/multifamily-residential',
                            // },
                            // {
                            //     title: 'Retail',
                            //     href: '/project/category/retail',
                            // },
                            // {
                            //     title: 'Single Family Residential',
                            //     href: '/project/category/single-family-residential',
                            // },
                        ]}
                    /> */}
                    {/* <DropDownList
                        title="Shop Real Estate"
                        items={[
                            {
                                title: 'MLS Search',
                                href: '/real-estate/buy/mls-search',
                            },
                        ]}
                    /> */}
                    <DropDownList
                        title="Services"
                        items={[
                            // {
                            //     title: 'Seller Services',
                            //     href: '/real-estate/sell/services',
                            // },
                            {
                                title: 'Free Property Evaluation',
                                href: '/real-estate/sell/evaluation',
                            },
                            {
                                title: 'Off-Market Listings',
                                href: '/real-estate/buy/off-market',
                            },
                            {
                                title: 'Sell Privately',
                                href: '/real-estate/sell/private-sell',
                            },
                            // {
                            //     title: 'Buyers Inventory',
                            //     href: '/real-estate/sell/buyers',
                            // },
                        ]}
                    />
                    <DropDownList
                        title="Insights & Research"
                        items={[
                            {
                                title: 'Latest Insights',
                                href: '/insights',
                            },
                        ]}
                    />
                    <DropDownList
                        title="‌Bayshire Team"
                        items={[
                            {
                                title: 'About Us',
                                href: '/team/about-us',
                            },
                            {
                                title: 'Contact Us',
                                href: '/team/contact-us',
                            },
                        ]}
                    />
                    <AuthBox />
                </List>
            </div>
        </Drawer>
    )
}

export default DrawerMenu
