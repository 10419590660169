import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import clsx from 'clsx'

import Link from '../../General/Link'

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },
}))

const MenuListItem = ({ title, href, nested, ...props }) => {
    const classes = useStyles()
    return (
        <Link href={href}>
            <ListItem
                button
                className={clsx({ [classes.nested]: nested })}
                {...props}
            >
                <ListItemText primary={title} />
            </ListItem>
        </Link>
    )
}

export default MenuListItem
