import React from 'react'
import Link from 'next/link'
import MuiLink from '@material-ui/core/Link'

const MyLink = ({ href, children, muiProps, ...props }) => (
    <Link href={href} passHref {...props}>
        <MuiLink underline="none" {...muiProps}>
            {children}
        </MuiLink>
    </Link>
)

export default MyLink
