import React from 'react';
import BuildIcon from '@material-ui/icons/Build';
import AssignmentIcon from '@material-ui/icons/Assignment';
import HomeIcon from '@material-ui/icons/Home';
import ApartmentIcon from '@material-ui/icons/Apartment';
import StoreIcon from '@material-ui/icons/Store';
import BusinessIcon from '@material-ui/icons/Business';
import LandscapeIcon from '@material-ui/icons/Landscape';

import { usePopupState, bindHover, bindMenu } from 'material-ui-popup-state/hooks';

import DropDownButton from '../Items/DropDownButton';
import NavigationMenuItem from '../Items/MenuItem';
import DropDownMenu from './DropDownMenu';

const categories = [
  {
    title: 'Pre-construction',
    slug: 'pre-construction',
    icon: <BuildIcon />,
  },
  // {
  //   title: 'Multifamily Residential',
  //   slug: 'multifamily-residential',
  //   icon: <ApartmentIcon />,
  // },
  // {
  //   title: 'Retail',
  //   slug: 'retail',
  //   icon: <StoreIcon />,
  // },
  // {
  //   title: 'Land',
  //   slug: 'land',
  //   icon: <BusinessIcon />,
  // },
  // {
  //   title: 'All Listings',
  //   slug: '',
  //   icon: <AssignmentIcon />,
  // },

  // {
  //   title: 'Single-family Residential',
  //   slug: 'single-family-residential',
  //   icon: <HomeIcon />,
  // },
];

const ProjectsDropdown = ({ small, from }) => {
  const state = usePopupState({
    variant: 'popover',
    popupId: 'projects-dropdown-menu',
  });

  return (
    <>
      <DropDownButton small={small} title="Kamal" buttonColor="#ECC490" {...bindHover(state)} />
      <DropDownMenu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        {...bindMenu(state)}
      >
        {categories.map((category, i) => (
          <NavigationMenuItem
            key={i}
            title={category.title}
            href={`/project${category.slug === 'all' ? `/${category.slug}` : `/category/${category.slug}`}`}
            icon={category.icon}
            click={state.close}
            from={from}
          />
        ))}
      </DropDownMenu>
    </>
  );
};

export default ProjectsDropdown;