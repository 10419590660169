import React from 'react'
import Image from 'next/image'
import PhoneIcon from '@material-ui/icons/PhoneRounded'
import {
    usePopupState,
    bindHover,
    bindMenu,
} from 'material-ui-popup-state/hooks'

import DropDownButton from '../Items/DropDownButton'
import NavigationMenuItem from '../Items/MenuItem'
import DropDownMenu from './DropDownMenu'

const TeamDropdown = ({ small, from }) => {
    const state = usePopupState({
        variant: 'popover',
        popupId: 'team-dropdown-menu',
    })

    return (
        <>
            <DropDownButton
                small={small}
                title="Bayshire Team"
                buttonColor="#ECC490"
                {...bindHover(state)}
            />
            <DropDownMenu
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                {...bindMenu(state)}
            >
                <NavigationMenuItem
                    title="About Us"
                    href="/team/about-us"
                    icon={
                        <span>
                            <Image
                                src="/favicon.ico"
                                alt="Rasti Team Icon"
                                width={20}
                                height={20}
                            />
                        </span>
                    }
                    click={state.close}
                    from={from}
                />
                <NavigationMenuItem
                    title="Contact Us"
                    href="/team/contact-us"
                    icon={<PhoneIcon />}
                    click={state.close}
                    from={from}
                />
            </DropDownMenu>
        </>
    )
}

export default TeamDropdown
