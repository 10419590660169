import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import BusinessIcon from '@material-ui/icons/Business';
import BuildIcon from '@material-ui/icons/Build';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ApartmentIcon from '@material-ui/icons/Apartment';
import StoreIcon from '@material-ui/icons/Store';
import LandscapeIcon from '@material-ui/icons/Landscape';
// import HomeIcon from '@material-ui/icons/Home';

import { usePopupState, bindHover, bindMenu } from 'material-ui-popup-state/hooks';

import DropDownButton from '../Items/DropDownButton';
import NavigationMenuItem from '../Items/MenuItem';
import DropDownMenu from './DropDownMenu';

const categories = [
  {
    title: 'MLS Search',
    slug: 'real-estate/buy/mls-search',
    icon: <SearchIcon />,
  },
  {
    title: 'Pre-construction',
    slug: 'project/category/pre-construction',
    icon: <BuildIcon />,
  },
  {
    title: 'Power of Sale',
    slug: 'project/category/power-of-sale',
    icon: <LandscapeIcon />,
  },
  {
    title: 'Assignments',
    slug: 'project/category/assignments',
    icon: <AssignmentIcon />,
  },
  {
    title: 'Land',
    slug: 'project/category/land',
    icon: <BusinessIcon />,
  },
  {
    title: 'All Listings',
    slug: 'project/category',
    icon: <StoreIcon />,
  },
];

const RealEstateDropdown = ({ small, from }) => {
  const state = usePopupState({
    variant: 'popover',
    popupId: 'real-estate-dropdown-menu',
  });

  return (
    <>
      <DropDownButton small={small} title="Real Estate" buttonColor="#ECC490" {...bindHover(state)} />
      <DropDownMenu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        {...bindMenu(state)}
      >
        {categories.map((category, i) => (
          <NavigationMenuItem
            key={i}
            title={category.title}
            href={`/${category.slug}`}
            icon={category.icon}
            click={state.close}
            from={from}
          />
        ))}
      </DropDownMenu>
    </>
  );
};

export default RealEstateDropdown;
