import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Menu from 'material-ui-popup-state/HoverMenu'

const DropDownMenu = withStyles({
    paper: {
        // width: 250,
    },
})((props) => (
    <Menu
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
))

export default DropDownMenu
