import React from 'react'
import SearchIcon from '@material-ui/icons/SearchRounded'
import LockIcon from '@material-ui/icons/LockRounded'
import VerifiedIcon from '@material-ui/icons/VerifiedUserRounded'

import {
    usePopupState,
    bindHover,
    bindMenu,
} from 'material-ui-popup-state/hooks'

import DropDownButton from '../Items/DropDownButton'
import NavigationMenuItem from '../Items/MenuItem'
import DropDownMenu from './DropDownMenu'

const BuyDropdown = ({ small, from }) => {
    const state = usePopupState({
        variant: 'popover',
        popupId: 'buy-dropdown-menu',
    })

    return (
        <>
            <DropDownButton
                small={small}
                title="Services"
                buttonColor="#ECC490"
                {...bindHover(state)}
            />
            <DropDownMenu
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                {...bindMenu(state)}
            >
                <NavigationMenuItem
                    title="Free Property Evaluation"
                    href="/real-estate/sell/evaluation"
                    icon={<VerifiedIcon />}
                    click={state.close}
                    from={from}
                />
                <NavigationMenuItem
                    title="Off-Market Listings"
                    href="/real-estate/buy/off-market"
                    icon={<LockIcon />}
                    click={state.close}
                    from={from}
                />
                <NavigationMenuItem
                    title="Sell Privately"
                    href="/real-estate/sell/private-sell"
                    icon={<LockIcon />}
                    click={state.close}
                    from={from}
                />
            </DropDownMenu>
        </>
    )
}

export default BuyDropdown
