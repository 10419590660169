import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import List from '@material-ui/core/List'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import clsx from 'clsx'

import NavigationListItem from './Items/ListItem'

const useStyles = makeStyles((theme) => ({
    active: {
        color: theme.palette.primary.main,
    },
}))

const MyDropDownList = ({ title, items, children }) => {
    const classes = useStyles()

    const [open, setOpen] = useState(false)
    const toggle = () => setOpen(!open)

    return (
        <React.Fragment>
            <ListItem
                button
                onClick={toggle}
                className={clsx({ [classes.active]: open })}
            >
                <ListItemText primary={title} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {items.map((i) => (
                        <NavigationListItem
                            key={i.href}
                            href={i.href}
                            title={i.title}
                            nested
                        />
                    ))}
                    {children}
                </List>
            </Collapse>
        </React.Fragment>
    )
}

export default MyDropDownList
