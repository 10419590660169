import React, { useContext } from 'react'
import Image from 'next/image'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'

import Link from './Link'
import { DarkModeContext } from '../../helpers/Theme'

// import LogoLight from '../../../public/images/logo/mase-rasti-team-logo-compact-new.png'
// import LogoDark from '../../../public/images/logo/mase-rasti-team-logo-compact-new-inverted.png'
import NavbarLogo from '../../../public/images/logo/Group.png'

const useStyles = makeStyles((theme) => ({
    logo: {
        position: 'relative',
        width: 200,
        height: ({ small }) => (small ? 50 : 100),
        [theme.breakpoints.down('md')]: {
            height: ({ small }) => (small ? 40 : 60),
        },
        [theme.breakpoints.down('sm')]: {
            height: ({ small }) => (small ? 30 : 50),
        },
        marginTop: ({ marginTop }) => (marginTop ? theme.spacing(0.5) : 0),
        zIndex: 1201
    },
}))

const Logo = ({ small, marginTop, from }) => {
    const classes = useStyles({ small, marginTop })
    const { dark } = useContext(DarkModeContext)

    let href = '/'
    if (from) {
        href = `/?from_name=${from.name}&from_url=${from.href}`
    }

    return (
        <Link href={href}>
            <Tooltip title="Click to visit the home page">
                <div className={classes.logo}>
                <img
                    src="/images/logo/brlogo.png"
                    alt="Bayshire Team Real Estate - North Toronto and Mississauga realtor"
                    style={{ objectFit: "contain", width: "100%", height: "100%" }}
                />
                </div>
            </Tooltip>
        </Link>
    )
}

export default Logo
